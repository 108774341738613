import { Fragment, ReactNode } from "react";

const links = ["alincofilter.com", "school.co.id", "mahkotaplastik.com"];

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
      clip-rule="evenodd"
    />
  </svg>
);

const Link = ({ href, children }: { href: string; children: ReactNode }) => (
  <a
    href={`${href}?utm_source=alinco.co.id&utm_medium=link&utm_campaign=coming-soon`}
    target="_blank"
    rel="noreferrer"
    className="text-xs md:text-sm text-slate-200 underline flex items-center"
  >
    {children} <Icon />
  </a>
);

const Links = ({ className = "" }: { className?: string }) => (
  <ul
    className={`flex flex-row items-center justify-center md:justify-end gap-2 ${className}`}
  >
    {links.map((link, index) => (
      <Fragment key={index}>
        {index >= 1 && (
          <li className="text-xs md:text-sm text-slate-200 hidden md:block">
            &bull;
          </li>
        )}
        <li key={link}>
          <Link href={`https://www.${link}`}>{link}</Link>
        </li>
      </Fragment>
    ))}
  </ul>
);

function App() {
  const now = new Date();

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="p-4 w-full text-right">
        <Links className="hidden md:flex" />
      </div>
      <div className="m-auto space-y-3 px-10">
        <h1 className="text-3xl md:text-5xl font-bold text-slate-100 text-center tracking-wide">
          Coming soon
        </h1>
        <p className="text-md lg:text-xl font-light text-slate-400 text-center">
          We are currently working on something awesome. Stay tuned!
        </p>
      </div>
      <div className="p-4 space-y-4">
        <div className="text-xs leading-relaxed md:text-sm md:leading-normal text-slate-300 font-light text-center">
          <div>
            &copy; {now.getFullYear()}{" "}
            <span className="font-semibold">ALINCO</span>. All rights reserved.
          </div>
          <div>
            <span className="font-semibold">ALINCO</span> is a registered
            trademark of{" "}
            <span className="font-semibold">PT Alinco Surabaya Indonesia</span>.
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
